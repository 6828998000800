<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">

          <v-card class="shadow rounded-lg">

            <v-row align="center" class="pa-0 ma-0 py-1 mx-1">
              <v-col cols="12" lg="3">
                <v-text-field v-model.trim="keyword"
                              class="rounded-input"
                              clearable
                              dense
                              hide-details
                              outlined
                              placeholder="Recherche..." prepend-inner-icon="mdi-magnify"
                              single-line/>
              </v-col>
              <v-col class="text-end" cols="12" lg="9">
                <v-btn class="ml-2" color="primary" text
                       @click="$refs.filterCampaignDialog.open()">
                  <v-icon left>mdi-filter</v-icon>
                  Filtre
                </v-btn>

                <v-btn color="primary" icon @click="getCampaigns">
                  <v-icon>mdi-table-refresh</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-divider/>

            <v-card-text>

              <v-row v-if="isLoading">
                <v-col v-for="(item,i) in 8" :key="i" cols="12" lg="4" md="4">
                  <v-card class="rounded-lg" outlined>
                    <v-card-text>
                      <v-skeleton-loader type="avatar,article"/>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-else>

                <v-col v-if="!campaigns.length" cols="12">
                  <v-card class="transparent" flat>
                    <v-card-text>
                      <NoResult/>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col v-for="(item,index) in campaigns"
                       :key="index"
                       class="d-flex flex-column"
                       cols="12"
                       lg="4"
                       md="4">
                  <v-card class="rounded-lg flex d-flex flex-column" outlined>
                    <v-card-title>
                      <v-avatar rounded size="50">
                        <v-img v-if="item.image" :src="$baseUrl + item.image"
                               class="zoom-pointer rounded-lg"
                               max-width="50"
                               min-height="50"
                               @click="$refs.lightbox.open(item.image)"/>

                        <v-img v-else
                               :src="require('@/assets/no-image.png')"
                               class="rounded-lg"
                               max-width="50"
                               min-height="50"></v-img>
                      </v-avatar>
                      <v-spacer/>
                      <v-chip :class="'bg-'+item.remaining_days.color+'-subtle'"
                              :text-color="item.remaining_days.color"
                              class="font-weight-medium fs-13"
                              dark
                              label
                              small>
                        {{ item.remaining_days.value }}
                      </v-chip>
                    </v-card-title>
                    <v-card-text class="flex">

                      <h3 class="pb-3 font-weight-medium grey--text text--darken-3">
                        {{ item.name }}
                      </h3>

                      <div class="mb-3">

                        <div class="d-block text-no-wrap">
                          <v-icon color="primary" small>mdi-calendar</v-icon>
                          <span class="font-weight-medium"> Date de début : </span>
                          {{ $func.dateFormat(item.start_date) }}
                        </div>

                        <div class="d-block text-no-wrap mt-2">
                          <v-icon color="primary" small>mdi-calendar</v-icon>
                          <span class="font-weight-medium"> Date de fin : </span>
                          {{ $func.dateFormat(item.end_date) }}
                        </div>

                        <div class="d-block text-no-wrap mt-2">
                          <v-icon color="primary" small>mdi-tag</v-icon>
                          <span class="font-weight-medium"> Type : </span>
                          {{ $func.campaignType(item.type) }}
                        </div>

                      </div>

                      <p class="grey--text mb-0">
                        {{ item.description }}
                      </p>
                      <v-row no-gutters>
                        <v-col cols="6">

                          <v-list-item class="pa-0">
                            <v-list-item-avatar class="bg-primary-subtle rounded-lg" size="30" tile>
                              <v-icon color="primary" small>mdi-shopping</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.total }}</v-list-item-title>
                              <v-list-item-subtitle>Total</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                        </v-col>
                        <v-col cols="6">
                          <v-list-item>
                            <v-list-item-avatar class="bg-orange-subtle rounded-lg" size="30" tile>
                              <v-icon color="orange" small>mdi-circle</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.switch }}</v-list-item-title>
                              <v-list-item-subtitle>Switch</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="6">
                          <v-list-item class="pa-0">
                            <v-list-item-avatar class="bg-blue-subtle rounded-lg" size="30" tile>
                              <v-icon color="blue" small>mdi-circle</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.sale }}</v-list-item-title>
                              <v-list-item-subtitle>Fidélisation</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="6">
                          <v-list-item>
                            <v-list-item-avatar class="bg-secondary-subtle rounded-lg" size="30" tile>
                              <v-icon color="secondary" small>mdi-circle</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.refusal }}</v-list-item-title>
                              <v-list-item-subtitle>Refus</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-divider/>

                    <v-card-actions class="pa-4">
                      <v-btn :to="'/supervisor-view/campaigns/'+item.id+'/sales'" color="primary" depressed>
                        <v-icon left>mdi-shopping-outline</v-icon>
                        Ventes
                      </v-btn>
                      <v-btn :to="'/supervisor-view/campaigns/'+item.id+'/assignment'" class="ml-2" color="primary"
                             text>
                        <v-icon left>mdi-map-check-outline</v-icon>
                        Affectation
                      </v-btn>
                    </v-card-actions>

                  </v-card>
                </v-col>

                <v-col cols="12">

                  <v-pagination v-if="total>10"
                                v-model="pagination.current"
                                :length="pagination.total"
                                color="secondary"
                                total-visible="6"
                                @input="onPageChange"
                  ></v-pagination>

                </v-col>
              </v-row>
            </v-card-text>

          </v-card>

        </v-col>

      </v-row>

      <Lightbox ref="lightbox"/>
      <FilterCampaignDialog ref="filterCampaignDialog"
                            @search="[pagination.current = 1,filter = $event,getCampaigns()]"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import FilterCampaignDialog from "@/views/campaigns/components/FilterCampaignDialog.vue";

export default {
    components: {FilterCampaignDialog},
    data() {
        return {
            campaigns: [],
            isLoading: false,

            keyword: null,
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },
            filter: {}
        }
    },

    methods: {
        getCampaigns() {
            this.isLoading = true
            HTTP.get('/supervisor-view/campaigns?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    per_page: this.per_page,
                    filter: this.filter
                }
            }).then((res) => {
                this.isLoading = false
                this.campaigns = res.data.data.data
                this.pagination.current = res.data.data.current_page
                this.pagination.total = res.data.data.last_page
                this.total = res.data.data.total

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        onPageChange() {
            this.getCampaigns();
        },
    },
    created() {
        this.getCampaigns()
    },
    watch: {
        keyword() {
            this.getCampaigns();
        },
        per_page() {
            this.pagination.current = 1
            this.getCampaigns();
        },
    },
}
</script>

<style scoped>

</style>